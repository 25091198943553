import {lazy, Suspense} from 'react';
import {Routes, Route} from 'react-router-dom';
import routes from 'config/routes';

const Home = lazy(() => import('components/pages/Home/Home'));
const WhatWeDo = lazy(() => import('components/pages/WhatWeDo/WhatWeDo'));
const Projects = lazy(() => import('components/pages/Projects/Projects'));
const OurVision = lazy(() => import('components/pages/OurVision/OurVision'));
const ContactUs = lazy(() => import('components/pages/ContactUs/ContactUs'));

const App = () => (
    <Suspense fallback={null}>

        {/* {isShownCookiesDialog && (
            <Cookies
                closeCookies={toggleCookiesDialogVisibility}
                suggestedLanguage={toggleCookiesDialogVisibility}
            />
        )} */}

        <Routes>
            <Route path={routes.home} element={<Home/>}/>
            <Route path={routes.whatWeDo} element={<WhatWeDo/>}/>
            <Route path={routes.projects} element={<Projects/>}/>
            <Route path={routes.ourVision} element={<OurVision/>}/>
            <Route path={routes.contactUs} element={<ContactUs/>}/>
        </Routes>
    </Suspense>
);

export default App;