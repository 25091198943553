const routes = {
    home: '/',
    whatWeDo: '/what-we-do',
    projects: '/projects',
    ourVision: '/our-vision',
    contactUs: '/contact-us',
    privacyPolicy: '/privacy-policy',
    cookiePolicy: '/cookie-policy',
    refundPolicy: '/refund-policy',
    imprint: '/imprint',
    redirectLink: '/link'
};

export default routes;
